// Define API base URL

// dev environment
// export const API_BASE_URL = 'http://tikao-dev-env.eba-kaeh43up.ap-south-1.elasticbeanstalk.com/api';
export const API_BASE_URL = 'https://api.livinnovate.co.in/api';

// localhost
// export const API_BASE_URL = 'http://localhost:8000/api';

// Define AWS User Access Key ID
export const AWS_ACCESS_KEY_ID = 'AKIA5WLTTP726QOLOOA3';

// Define AWS User Secret Access Key
export const AWS_SECRET_ACCESS_KEY = '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';

// Define AWS Region
export const AWS_REGION = 'ap-south-1';

// Define AWS User Bucket Name
export const AWS_S3_BUCKET_NAME = 'tikao-admin-dev';

// localhost
// export const REACT_APP_BASE_URL = 'http://localhost:3000';

// dev environment
export const REACT_APP_BASE_URL = 'https://app.livinnovate.co.in';

// localhost
// export const SOCKET_BASE_URL = 'http://localhost:8000';

// dev environment
export const SOCKET_BASE_URL = 'https://api.livinnovate.co.in';
