export const translations = {
	default: {
		courseCreation: 'Course Creation',
		selectLanguage: 'Select Language',
		chooseVideo: 'Choose video file here',
		removeVideo: 'Remove Video',
		publishCourse: 'Publish Course',
		addCourse: 'Add Course in another language',
		titleError: 'Course title is required',
		descriptionError: 'Description is required',
		subjectError: 'Subject is required',
		chapterError: 'Chapter is required',
		thumbnailError: 'Thumbnail is required',
		videoError: 'Video is required',
		subject: 'Subject',
		selectSubject: 'Select Subject',
		chapter: 'Chapter',
		selectChapter: 'Select Chapter',
		courseTitle: 'Course Title (maximum 12 words)',
		description: 'Description (maximum 75 words)',
		words: 'words',
		uploadThumbnail: {
			description: 'Upload Thumbnail (Landscape)',
			sizeLimit: '(JPEG/JPG, Less than 3MB)',
		},
		dragAndDropImage: 'Drag and drop your image here or click to select',
		noFileSelected: 'No file selected',
		remove: 'Remove',
		timestampTagging: 'Timestamp Tagging with Quiz (maximum 7)',
		timestampTitle: 'Timestamp Title',
		from: 'From',
		to: 'To',
		timeBound: 'Time Bound',
		resumeAfterSeconds: 'Resume After (seconds)',
		chooseQuizToAdd: 'Choose Quiz to Add',
		saveTimestamp: 'Save Timestamp',
		savedTimestamps: 'Saved Timestamps',
		timestamp: 'Timestamp',
		title: 'Title',
		descriptionshort: 'Description',
		quiz: 'Quiz',
		question: 'Question',
		options: 'Options',
	},
	en: {
		courseCreation: 'Course Creation',
		selectLanguage: 'Select Language',
		chooseVideo: 'Choose video file here',
		removeVideo: 'Remove Video',
		publishCourse: 'Publish Course',
		addCourse: 'Add Course in another language',
		titleError: 'Course title is required',
		descriptionError: 'Description is required',
		subjectError: 'Subject is required',
		chapterError: 'Chapter is required',
		thumbnailError: 'Thumbnail is required',
		videoError: 'Video is required',
		subject: 'Subject',
		selectSubject: 'Select Subject',
		chapter: 'Chapter',
		selectChapter: 'Select Chapter',
		courseTitle: 'Course Title (maximum 12 words)',
		description: 'Description (maximum 75 words)',
		words: 'words',
		uploadThumbnail: {
			description: 'Upload Thumbnail (Landscape)',
			sizeLimit: '(JPEG/JPG, Less than 3MB)',
		},
		dragAndDropImage: 'Drag and drop your image here or click to select',
		noFileSelected: 'No file selected',
		remove: 'Remove',
		timestampTagging: 'Timestamp Tagging with Quiz (maximum 7)',
		timestampTitle: 'Timestamp Title',
		from: 'From',
		to: 'To',
		timeBound: 'Time Bound',
		resumeAfterSeconds: 'Resume After (seconds)',
		chooseQuizToAdd: 'Choose Quiz to Add',
		saveTimestamp: 'Save Timestamp',
		savedTimestamps: 'Saved Timestamps',
		timestamp: 'Timestamp',
		title: 'Title',
		descriptionshort: 'Description',
		quiz: 'Quiz',
		question: 'Question',
		options: 'Options',
	},
	hi: {
		courseCreation: 'कोर्स निर्माण',
		selectLanguage: 'भाषा चुनें',
		chooseVideo: 'यहाँ वीडियो फ़ाइल चुनें',
		removeVideo: 'वीडियो हटाएँ',
		publishCourse: 'कोर्स प्रकाशित करें',
		addCourse: 'एक और भाषा में कोर्स जोड़ें',
		titleError: 'कोर्स शीर्षक आवश्यक है',
		descriptionError: 'विवरण आवश्यक है',
		subjectError: 'विषय आवश्यक है',
		chapterError: 'अध्याय आवश्यक है',
		thumbnailError: 'थंबनेल आवश्यक है',
		videoError: 'वीडियो आवश्यक है',
		subject: 'विषय',
		selectSubject: 'विषय का चयन करें:',
		chapter: 'अध्याय',
		selectChapter: 'अध्याय का चयन करें:',
		courseTitle: 'कोर्स का शीर्षक (अधिकतम 12 शब्द)',
		words: 'शब्द',
		uploadThumbnail: {
			description: 'थंबनेल अपलोड करें (लैंडस्केप)',
			sizeLimit: '(JPEG/JPG, 3MB से कम)',
		},
		dragAndDropImage: 'यहाँ अपनी छवि खींचें और छोड़ें या चयन के लिए क्लिक करें',
		noFileSelected: 'कोई फ़ाइल चयनित नहीं',
		remove: 'हटाएँ',
		description: 'विवरण (अधिकतम 75 शब्द)',
		timestampTagging: 'क्विज़ के साथ टाइमस्टैम्प टैगिंग (अधिकतम 7)',
		timestampTitle: 'टाइमस्टैम्प शीर्षक',
		from: 'से',
		to: 'तक',
		timeBound: 'समय सीमा',
		resumeAfterSeconds: 'दूसरे के बाद फिर से शुरू करें',
		chooseQuizToAdd: 'जोड़ने के लिए क्विज़ चुनें',
		saveTimestamp: 'टाइमस्टैम्प को सहेजें',
		savedTimestamps: 'सहेजे गए टाइमस्टैम्प',
		timestamp: 'टाइमस्टैम्प',
		title: 'शीर्षक',
		descriptionshort: 'विवरण',
		quiz: 'क्विज़',
		questions: 'प्रश्न',
		options: 'विकल्प',
	},
	bn: {
		courseCreation: 'কোর্স তৈরি',
		selectLanguage: 'ভাষা নির্বাচন করুন',
		chooseVideo: 'এখানে ভিডিও ফাইল নির্বাচন করুন',
		removeVideo: 'ভিডিও মুছুন',
		publishCourse: 'কোর্স প্রকাশ করুন',
		addCourse: 'আরেকটি ভাষায় কোর্স যোগ করুন',
		titleError: 'কোর্সের শিরোনাম আবশ্যক',
		descriptionError: 'বর্ণনা আবশ্যক',
		subjectError: 'বিষয় আবশ্যক',
		chapterError: 'অধ্যায় আবশ্যক',
		thumbnailError: 'থাম্বনেল আবশ্যক',
		videoError: 'ভিডিও আবশ্যক',
		subject: 'বিষয়',
		selectSubject: 'বিষয় নির্বাচন করুন:',
		chapter: 'অধ্যায়',
		selectChapter: 'অধ্যায় নির্বাচন করুন:',
		courseTitle: 'কোর্সের শিরোনাম (সর্বাধিক 12 শব্দ)',
		uploadThumbnail: {
			description: 'থাম্বনেইল আপলোড করুন (ল্যান্ডস্কেপ)',
			sizeLimit: '(JPEG/JPG, 3MB এর কম)',
		},
		dragAndDropImage: 'এখানে আপনার ছবি টেনে আনুন অথবা নির্বাচন করতে ক্লিক করুন',
		noFileSelected: 'কোন ফাইল নির্বাচিত নয়',
		remove: 'মুছুন',
		description: 'বিবরণ (সর্বাধিক 75 শব্দ)',
		timestampTagging: 'কুইজের সাথে টাইমস্ট্যাম্প ট্যাগিং (সর্বাধিক 7)',
		timestampTitle: 'টাইমস্ট্যাম্প শিরোনাম',
		from: 'থেকে',
		to: 'যাতে',
		timeBound: 'সময়সীমা',
		resumeAfterSeconds: 'সেকেন্ড পর আবার শুরু করুন',
		chooseQuizToAdd: 'যোগ করার জন্য কুইজ নির্বাচন করুন',
		saveTimestamp: 'টাইমস্ট্যাম্প সংরক্ষণ করুন',
		savedTimestamps: 'সংরক্ষিত টাইমস্ট্যাম্প',
		timestamp: 'টাইমস্ট্যাম্প',
		title: 'শিরোনাম',
		quiz: 'কুইজ',
		descriptionshort: 'বিবরণ',
		questions: 'প্রশ্ন',
		options: 'অপশন',
	},
};
