import React, { useState } from 'react';
import { useCreateMutation } from '../query';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { uploadCourseThumbnail, uploadVideo } from '../aws/s3';
import CourseForm from '../components/course/CourseForm';
import { MdDelete } from 'react-icons/md';

const CourseCreation = () => {
	const [languages] = useState({
		en: 'English',
		hi: 'Hindi',
		bn: 'Bengali',
	});
	const [subjects, setSubjects] = useState([]);
	const [chapters, setChapters] = useState([]);
	const [selectedSubject, setSelectedSubject] = useState(null);
	const [selectedChapter, setSelectedChapter] = useState(null);
	const [expandedForm, setExpandedForm] = useState(0); // State to track which form is expanded

	const [isLoading, setIsLoading] = useState(false);

	const createMutation = useCreateMutation();

	// State to store the forms and selected languages
	const [forms, setForms] = useState([
		{
			selectedLanguage: 'default',
			title: '',
			description: '',
			subject: selectedSubject,
			chapter: selectedSubject,
			thumbnail: null,
			thumbnailSrc: null,
			thumbnailFile: null,
			video: null,
			videoSrc: null,
			videoFile: null,
			timestamps: [],
		},
	]);

	// Add new form (if the limit of 3 is not exceeded)
	const addNewCourseForm = () => {
		if (forms.length < 3) {
			setForms((prevForms) => [
				...prevForms,
				{
					selectedLanguage: 'default',
					title: '',
					description: '',
					subject: selectedSubject,
					chapter: selectedSubject,
					thumbnail: null,
					thumbnailSrc: null,
					thumbnailFile: null,
					video: null,
					videoSrc: null,
					videoFile: null,
					timestamps: [],
				},
			]);
			setExpandedForm(forms.length);
		} else {
			toast.error('You can only add courses in the available languages');
		}
	};

	// Function to handle when a language is selected in any form
	const handleLanguageChange = (index, selectedLanguage) => {
		setForms((prevForms) => {
			const updatedForms = [...prevForms];
			updatedForms[index].selectedLanguage = selectedLanguage;
			return updatedForms;
		});
	};

	// Function to filter available languages for each form
	const getAvailableLanguagesForForm = (index) => {
		const selectedLanguages = forms.map((form) => form.selectedLanguage);
		// Filter languages and convert the result back into an object
		const availableLanguages = Object.entries(languages)
			.filter(
				([key]) =>
					!selectedLanguages.includes(key) ||
					forms[index].selectedLanguage === key
			)
			.reduce((acc, [key, value]) => {
				acc[key] = value;
				return acc;
			}, {});

		return availableLanguages;
	};

	const uploadVideoAndThumbnail = async (form) => {
		try {
			const [responseThumbnail, responseVideo] = await Promise.all([
				uploadCourseThumbnail(
					form?.thumbnailFile,
					form?.thumbnailFile?.name,
					form?.thumbnailFile?.type
				),
				uploadVideo(form?.videoFile, form?.videoFile.name, form?.videoFile.type),
			]);

			console.log(responseThumbnail, responseVideo);

			return { responseThumbnail, responseVideo };
		} catch (error) {
			toast.error('Error uploading files: ' + error.message);
			setIsLoading(false);
			return null;
		}
	};

	const handlePublish = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		console.log(forms);

		const courseData = {
			localizedContent: {},
			subject: selectedSubject._id,
			chapter: selectedChapter._id,
		};

		let isValid = true;

		// Check if subject and chapter are selected
		if (!selectedSubject) {
			isValid = false;
			toast.error('Please select a subject.');
			setIsLoading(false);
			return;
		}

		if (!selectedChapter) {
			isValid = false;
			toast.error('Please select a chapter.');
			setIsLoading(false);
			return;
		}

		// Loop through forms and validate asynchronously
		for (let index = 0; index < forms.length; index++) {
			const form = forms[index];

			if (
				!form.selectedLanguage ||
				!form.title ||
				!form.description ||
				!form.thumbnailSrc ||
				!form.videoSrc
			) {
				isValid = false;
				toast.error(`Please fill out all fields in form ${index + 1}`);
				return;
			} else {
				const { responseThumbnail, responseVideo } =
					await uploadVideoAndThumbnail(form);

				if (!responseThumbnail || !responseVideo) {
					isValid = false;
					toast.error('Error uploading files');
					setIsLoading(false);
					return;
				}

				courseData.localizedContent[form.selectedLanguage] = {
					title: form.title,
					description: form.description,
					thumbnail: responseThumbnail,
					video: responseVideo,
					timestamps: form.timestamps,
				};
			}
		}

		// If all forms are valid, proceed with publishing
		if (isValid) {
			console.log(courseData);

			try {
				createMutation.mutate(
					{ url: 'course', data: courseData, queryKey: 'Course' },
					{
						onSuccess: () => {
							setForms([
								{
									selectedLanguage: 'default',
									title: '',
									description: '',
									subject: selectedSubject,
									chapter: selectedSubject,
									thumbnail: null,
									thumbnailSrc: null,
									thumbnailFile: null,
									video: null,
									videoSrc: null,
									videoFile: null,
									timestamps: [],
								},
							]);
							setExpandedForm(0);
							// toast.success('Course published successfully!');
						},
					}
				);
			} catch (error) {
				toast.error('Error Creating course: ' + error.message);
			} finally {
				setIsLoading(false);
			}
		}
		setIsLoading(false);
	};

	const toggleForm = (index) => {
		setExpandedForm(expandedForm === index ? null : index); // Toggle the form state
	};

	const deleteCourseForm = (index) => {
		setForms((prevForms) => prevForms.filter((_, i) => i !== index));
	};

	return (
		<div className="p-6 px-2 space-y-6 max-w-2xl mx-auto bg-white shadow-md rounded-lg">
			<ToastContainer />
			<h1 className="text-2xl font-bold mb-4 w-full text-center">
				Course Creation
			</h1>

			{forms.map((form, index) => (
				<div key={index} className="mb-4">
					{/* Form Header - Click to toggle */}
					<div
						className="cursor-pointer bg-gray-200 p-2 rounded flex justify-between w-full"
						onClick={() => toggleForm(index)}
					>
						<h2 className="text-xl font-semibold">
							Course Form {index + 1}
							{'  '}
							{form.selectedLanguage !== 'default' &&
								`  (${languages[form.selectedLanguage]})`}
						</h2>
						<button
							className="ml-2 text-red-600"
							onClick={(e) => {
								e.stopPropagation(); // Prevent the toggle action
								deleteCourseForm(index);
							}}
						>
							<MdDelete size={24} />
						</button>
					</div>

					{/* Collapsible Form Body */}
					{expandedForm === index && (
						<div className="p-2 bg-gray-100 rounded-b-lg">
							<CourseForm
								availableLanguages={getAvailableLanguagesForForm(index)} // Pass filtered languages
								onLanguageChange={handleLanguageChange} // Handle language selection
								subjects={subjects}
								chapters={chapters}
								setSubjects={setSubjects}
								setChapters={setChapters}
								selectedSubject={selectedSubject}
								setSelectedSubject={setSelectedSubject}
								selectedChapter={selectedChapter}
								setSelectedChapter={setSelectedChapter}
								form={form}
								setForm={setForms}
								index={index}
								key={index}
							/>
						</div>
					)}
				</div>
			))}

			{/* Add another language Button */}
			<div className="flex justify-end w-full">
				<button
					onClick={(e) => {
						e.preventDefault();
						addNewCourseForm();
					}}
					className="mt-6 p-2 bg-blue-500 text-white rounded w-full"
				>
					Add course in another language
				</button>
			</div>

			{/* Save Button */}
			<div className="flex justify-end w-full">
				<button
					onClick={(e) => handlePublish(e)}
					className="p-2 bg-green-700 text-white rounded w-full"
					disbaled={isLoading}
				>
					{isLoading ? 'Publishing your Course...' : 'Publish Course'}
				</button>
			</div>
		</div>
	);
};

export default CourseCreation;
