import React, { useState, useEffect } from 'react';
import { useGetQuery } from '../query';
import { ToastContainer } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import QuizForm from '../components/quiz/QuizForm';
import QuizItem from '../components/quiz/QuizItem';

const Quiz = () => {
	const [subjects, setSubjects] = useState([]);
	const [chapters, setChapters] = useState([]);
	const [selectedSubject, setSelectedSubject] = useState(null);
	const [selectedSubjectValue, setSelectedSubjectValue] = useState('');
	const [selectedChapter, setSelectedChapter] = useState(null);
	const [selectedChapterValue, setSelectedChapterValue] = useState('');

	// Fetch subjects
	const {
		data: subjectData,
		error: subjectError,
		isLoading: subjectLoading,
	} = useGetQuery({
		queryKey: ['Subject'],
		url: `subject`,
	});

	// Fetch Chapters
	const {
		data: chapterData,
		error: chapterError,
		isLoading: chapterLoading,
	} = useGetQuery({
		queryKey: ['Chapter'],
		url: `chapter/subject/${selectedSubjectValue}`,
		enabled: !!selectedSubjectValue,
	});

	// Fetch quizzes
	const { data: quizData, isLoading: quizLoading } = useGetQuery({
		queryKey: ['Quiz', selectedChapter?._id], // Invalidate based on selectedChapter
		url: selectedChapter?._id && `quiz/chapter/${selectedChapter._id}`, // Only call API if chapter is selected
		enabled: !!selectedChapter?._id, // Trigger only when selectedChapter is set
	});

	useEffect(() => {
		if (subjectData) {
			setSubjects(subjectData?.subjects);
		}
	}, [subjectData]);

	useEffect(() => {
		if (chapterData) {
			setChapters(chapterData?.chapters);
		}
	}, [chapterData]);

	const handleSubjectChange = (e) => {
		const selectedSubjectId = e.target.value;
		setSelectedSubjectValue(selectedSubjectId);

		const foundSubject = subjects.find(
			(subject) => subject._id === selectedSubjectId
		);
		setSelectedSubject(foundSubject || null);
		setSelectedChapter(null);
		setSelectedChapterValue('');
		// setChapters(foundSubject ? foundSubject.chapters : []);
	};

	// Handle chapter change
	const handleChapterChange = (e) => {
		const selectedChapterId = e.target.value;
		setSelectedChapterValue(selectedChapterId);

		const foundChapter = chapters.find(
			(chapter) => chapter._id === selectedChapterId
		);
		setSelectedChapter(foundChapter || null);
	};

	if (subjectLoading) return <ClipLoader color="#000000" />;

	return (
		<div className="p-6 space-y-6 max-w-2xl mx-auto bg-white shadow-md rounded-lg">
			<ToastContainer />
			<h2 className="text-2xl font-semibold mb-4 w-full text-center">
				Manage Quiz
			</h2>

			<div className="mb-6">
				<label className="block text-gray-700 mb-2">Select Subject</label>
				<select
					className="p-2 border rounded w-full"
					value={selectedSubjectValue}
					onChange={handleSubjectChange}
				>
					<option value="" disabled>
						Select Subject
					</option>
					{subjects
						.filter((subject) => subject.active)
						.map((subject) => (
							<option key={subject._id} value={subject._id}>
								{subject.title}
							</option>
						))}
				</select>
			</div>

			<div className="mb-6">
				<label className="block text-gray-700 mb-2">Select Chapter</label>
				<select
					className="p-2 border rounded w-full"
					value={selectedChapterValue}
					onChange={handleChapterChange}
					disabled={!selectedSubjectValue}
				>
					<option value="" disabled>
						Select Chapter
					</option>
					{chapters
						.filter((chapter) => chapter.active)
						.map((chapter) => (
							<option key={chapter._id} value={chapter._id}>
								{chapter.title}
							</option>
						))}
				</select>
			</div>

			<QuizForm
				selectedChapter={selectedChapter}
				setSelectedChapter={setSelectedChapter}
				setSelectedSubject={setSelectedSubject}
			/>

			{quizLoading ? (
				<ClipLoader color="#000000" />
			) : !selectedSubject ? (
				<p className="text-gray-500">Please select a subject</p>
			) : !selectedChapter ? (
				<p className="text-gray-500">Please select a chapter</p>
			) : (
				<div className="mt-8">
					<h2 className="text-2xl font-bold mb-4">
						List of Quizzes for selected Chapter
					</h2>
					{quizData &&
						quizData.quiz.map((quiz) => (
							<QuizItem
								key={quiz._id}
								quiz={quiz}
								selectedChapter={selectedChapter.title}
								selectedSubject={selectedSubject.title}
								allowDelete={true}
							/>
						))}
				</div>
			)}

			{subjectLoading && <ClipLoader color="#000000" />}
			{subjectError && (
				<p className="text-red-500">Error: {subjectError.message}</p>
			)}
		</div>
	);
};

export default Quiz;
