import React, { useState, useEffect } from 'react';
import { useDeleteMutation, useGetQuery, useUpdateMutation } from '../query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';
import { Link, useNavigate } from 'react-router-dom';
import {  MdDelete, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useQueryClient } from '@tanstack/react-query';

const Course = () => {
	const navigate = useNavigate();

	const [subjects, setSubjects] = useState([]);
	const [chapters, setChapters] = useState([]);
	const [selectedSubject, setSelectedSubject] = useState(null);
	const [selectedSubjectValue, setSelectedSubjectValue] = useState('');
	const [selectedChapter, setSelectedChapter] = useState(null);
	const [selectedChapterValue, setSelectedChapterValue] = useState('');
	const [chapterCourses, setChapterCourses] = useState([]);

	const queryClient = useQueryClient();
	const deleteMutation = useDeleteMutation();
	const updateMutation = useUpdateMutation();

	// Fetch subjects
	const {
		data: subjectData,
		error: subjectError,
		isLoading: subjectLoading,
	} = useGetQuery({
		queryKey: ['Subject'],
		url: `subject`,
	});

	// Fetch chapters
	const {
		data: chapterData,
		error: chapterError,
		isLoading: chapterLoading,
	} = useGetQuery({
		queryKey: ['Chapter'],
		url: `chapter/subject/${selectedSubjectValue}`,
		enabled: !!selectedSubjectValue,
	});

	// Fetch quizzes
	const { data: courseData, isLoading: courseLoading } = useGetQuery({
		queryKey: ['Course', selectedChapter?._id], // Invalidate based on selectedChapter
		url: selectedChapter?._id && `course/chapter/${selectedChapter._id}`, // Only call API if chapter is selected
		enabled: !!selectedChapter?._id, // Trigger only when selectedChapter is set
	});

	useEffect(() => {
		if (subjectData) {
			setSubjects(subjectData?.subjects);
		}
	}, [subjectData]);

	useEffect(() => {
		if (chapterData) {
			setChapters(chapterData?.chapters);
		}
	}, [chapterData]);

	useEffect(() => {
		if (courseData) {
			setChapterCourses(courseData?.courses);
		}
	}, [courseData]);

	const handleSubjectChange = (e) => {
		const selectedSubjectId = e.target.value;
		setSelectedSubjectValue(selectedSubjectId);

		const foundSubject = subjects.find(
			(subject) => subject._id === selectedSubjectId
		);
		setSelectedSubject(foundSubject || null);
		setSelectedChapter(null);
		setSelectedChapterValue('');
	};

	// Handle chapter change
	const handleChapterChange = (e) => {
		const selectedChapterId = e.target.value;
		setSelectedChapterValue(selectedChapterId);

		const foundChapter = chapters.find(
			(chapter) => chapter._id === selectedChapterId
		);
		setSelectedChapter(foundChapter || null);
	};

	const deleteCourse = (index) => {
		deleteMutation.mutate(
			{
				id: chapterCourses.courses[index]._id,
				url: 'course',
				queryKey: 'Course',
			},
			{
				onSuccess: () => {
					// Remove the chapter from local state
					queryClient.invalidateQueries('Subject');
					queryClient.invalidateQueries('Course');
					// toast.success('Chapter deleted successfully!');
					// Clear selected subject chapters after successful deletion
					setChapterCourses(chapterCourses.filter((_, i) => i !== index));
				},
			}
		);
	};

	const updateCourse = (courseId, updatedData) => {
		updateMutation.mutate({
			id: courseId,
			data: updatedData,
			url: 'course',
			queryKey: 'Course',
		});
	};

	const toggleActiveStatus = (id) => {
		// Find the subject and toggle its isActive status
		const updatedCourses = chapterCourses.map((chapter) =>
			chapter._id === id ? { ...chapter, active: !chapter.active } : chapter
		);
		setChapterCourses(updatedCourses);

		// Call updateSubject with the new active status
		const updatedCourse = updatedCourses.find((subject) => subject._id === id);
		console.log(updatedCourse);
		updateCourse(id, {
			title: updatedCourse.title,
			active: updatedCourse.active,
		}); // Pass the new active status
	};

	if (subjectLoading) return <ClipLoader color="#000000" />;

	return (
		<div className="p-6 space-y-6 max-w-2xl mx-auto bg-white shadow-md rounded-lg">
			<ToastContainer />
			<h2 className="text-2xl font-semibold mb-4 w-full text-center">
				Manage Courses
			</h2>

			<div className="mt-4">
				<Link to="/create-course">
					<button className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition">
						Create New Course
					</button>
				</Link>
			</div>

			<div className="mb-6">
				<label className="block text-gray-700 mb-2">Select Subject</label>
				<select
					className="p-2 border rounded w-full"
					value={selectedSubjectValue}
					onChange={handleSubjectChange}
				>
					<option value="" disabled>
						Select Subject
					</option>
					{subjects
						.filter((subject) => subject.active)
						.map((subject) => (
							<option key={subject._id} value={subject._id}>
								{subject.title}
							</option>
						))}
				</select>
			</div>

			<div className="mb-6">
				<label className="block text-gray-700 mb-2">Select Chapter</label>
				<select
					className="p-2 border rounded w-full"
					value={selectedChapterValue}
					onChange={handleChapterChange}
					disabled={!selectedSubjectValue}
				>
					<option value="" disabled>
						Select Chapter
					</option>
					{chapters
						.filter((chapter) => chapter.active)
						.map((chapter) => (
							<option key={chapter._id} value={chapter._id}>
								{chapter.title}
							</option>
						))}
				</select>
			</div>

			{courseLoading ? (
				<div className="flex justify-center items-center h-48">
					<ClipLoader size={50} color={'#4A90E2'} loading={courseLoading} />
				</div>
			) : !selectedSubject ? (
				<p className="text-gray-500">Please select a subject</p>
			) : !selectedChapter ? (
				<p className="text-gray-500">Please select a chapter</p>
			) : (
				<div className="mb-4">
					<h3 className="text-xl font-semibold mb-2">
						Course Already Present in the Chapter:
					</h3>
					{courseData?.courses?.length === 0 ? (
						<li className="text-gray-500 ml-5">
							Start adding your course
						</li>
					) : (
						<ul className="list-disc">
							{chapterCourses?.map((course, index) => (
								<li
									key={index}
									className="flex items-center mb-2 p-4 px-8 border rounded shadow-sm"
								>
									<div className="flex flex-col gap-1 w-full">
										<span className="flex-grow">
											<strong>Title:</strong>{' '}
											{course?.localizedContent?.en?.title ||
												course?.localizedContent?.hi?.title ||
												course?.localizedContent?.bn?.title}
										</span>
										<span className="flex-grow">
											<strong>Description:</strong>{' '}
											{course?.localizedContent?.en?.description ||
												course?.localizedContent?.hi?.description ||
												course?.localizedContent?.bn?.description}
										</span>
									</div>
									<div className="flex items-center justify-center space-x-4">
										<button
											type="button"
											className="text-blue-500 py-0.5 px-3 border-2 border-blue-500 rounded-lg"
											onClick={() => navigate(`/preview-video/${course._id}`)}
										>
											Preview
										</button>
										{course.active ? (
											<MdVisibility
												onClick={() => toggleActiveStatus(course._id)}
												className="text-green-500 text-xl cursor-pointer"
											/>
										) : (
											<MdVisibilityOff
												onClick={() => toggleActiveStatus(course._id)}
												className="text-gray-500 text-xl cursor-pointer"
											/>
										)}
										<button
											onClick={() => deleteCourse(index)}
											className="text-red-500"
										>
											<MdDelete size={20} />
										</button>
									</div>
								</li>
							))}
						</ul>
					)}
				</div>
			)}
		</div>
	);
};

export default Course;
