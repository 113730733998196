import {
	PutObjectCommand,
	S3Client,
	CreateMultipartUploadCommand,
	UploadPartCommand,
	CompleteMultipartUploadCommand,
	AbortMultipartUploadCommand,
} from '@aws-sdk/client-s3';
import {
	AWS_ACCESS_KEY_ID,
	AWS_REGION,
	AWS_S3_BUCKET_NAME,
	AWS_SECRET_ACCESS_KEY,
} from '../lib/constant';

// Initialize the S3Client with your credentials
const client = new S3Client({
	region: AWS_REGION,
	credentials: {
		accessKeyId: AWS_ACCESS_KEY_ID,
		secretAccessKey: AWS_SECRET_ACCESS_KEY,
	},
});

// Generate a unique name for each file upload
const generateUniqueName = (name) => {
	const timestamp = Date.now(); // Get the current timestamp
	return `${timestamp}-${name}`; // Append the timestamp to the name
};

const twentyFiveMB = 25 * 1024 * 1024;
const minimumPartSize = 5 * 1024 * 1024; // 5 MB

export const createString = (size = twentyFiveMB) => {
	return 'x'.repeat(size);
};

// Function to upload a video
export const uploadVideo = async (file, name, type) => {
	const uniqueName = generateUniqueName(name);
	let uploadId;

	try {
		// Create a multipart upload
		const multipartUpload = await client.send(
			new CreateMultipartUploadCommand({
				Bucket: AWS_S3_BUCKET_NAME,
				Key: `courses/videos/${uniqueName}`,
				ContentType: type,
			})
		);

		uploadId = multipartUpload.UploadId;

		const uploadPromises = [];
		const totalParts = Math.ceil(file.size / minimumPartSize);

		// Upload each part
		for (let i = 0; i < totalParts; i++) {
			const start = i * minimumPartSize;
			const end = Math.min(start + minimumPartSize, file.size);
			const partBuffer = file.slice(start, end); // Using File.slice for Blob handling

			uploadPromises.push(
				client
					.send(
						new UploadPartCommand({
							Bucket: AWS_S3_BUCKET_NAME,
							Key: `courses/videos/${uniqueName}`,
							UploadId: uploadId,
							Body: partBuffer,
							PartNumber: i + 1,
						})
					)
					.then((data) => {
						console.log('Part', i + 1, 'uploaded');
						return data;
					})
					.catch((error) => {
						console.error(`Error uploading part ${i + 1}:`, error);
						throw error; // Rethrow to handle in the main try/catch
					})
			);
		}

		// Wait for all parts to finish uploading
		const uploadResults = await Promise.all(uploadPromises);

		// Complete the multipart upload
		await client.send(
			new CompleteMultipartUploadCommand({
				Bucket: AWS_S3_BUCKET_NAME,
				Key: `courses/videos/${uniqueName}`,
				UploadId: uploadId,
				MultipartUpload: {
					Parts: uploadResults.map(({ ETag }, i) => ({
            ETag,
            PartNumber: i + 1,
          })),
				},
			})
		);

		console.log('Video uploaded successfully');
		// Return the S3 URL of the uploaded video
		return `https://${AWS_S3_BUCKET_NAME}.s3.${AWS_REGION}.amazonaws.com/courses/videos/${uniqueName}`;
	} catch (error) {
		console.error('Error uploading video:', error);

		// Abort the multipart upload if it was initiated
		if (uploadId) {
			const abortCommand = new AbortMultipartUploadCommand({
				Bucket: AWS_S3_BUCKET_NAME,
				Key: `courses/videos/${uniqueName}`,
				UploadId: uploadId,
			});

			await client.send(abortCommand);
			console.log('Multipart upload aborted.');
		}

		throw error; // Rethrow the error for further handling
	}
};

// Function to upload a thumbnail
export const uploadCourseThumbnail = async (file, name, type) => {
	const uniqueName = generateUniqueName(name);

	// Define the S3 upload parameters
	const command = new PutObjectCommand({
		Bucket: AWS_S3_BUCKET_NAME,
		Key: `courses/thumbnails/${uniqueName}`, // Unique name for the thumbnail
		Body: file, // File content
		ContentType: type, // MIME type of the file
	});

	try {
		// Send the upload command to S3
		const response = await client.send(command);
		console.log('Thumbnail uploaded successfully:', response);

		// Return the S3 URL of the uploaded file
		return `https://${AWS_S3_BUCKET_NAME}.s3.${AWS_REGION}.amazonaws.com/courses/thumbnails/${uniqueName}`;
	} catch (error) {
		console.error('Error uploading thumbnail:', error);
		throw error;
	}
};
// Function to upload a thumbnail
export const uploadSubjectThumbnail = async (file, name, type) => {
	const uniqueName = generateUniqueName(name);

	// Define the S3 upload parameters
	const command = new PutObjectCommand({
		Bucket: AWS_S3_BUCKET_NAME,
		Key: `subjects/thumbnails/${uniqueName}`, // Unique name for the thumbnail
		Body: file, // File content
		ContentType: type, // MIME type of the file
	});

	try {
		// Send the upload command to S3
		const response = await client.send(command);
		console.log('Thumbnail uploaded successfully:', response);

		// Return the S3 URL of the uploaded file
		return `https://${AWS_S3_BUCKET_NAME}.s3.${AWS_REGION}.amazonaws.com/subjects/thumbnails/${uniqueName}`;
	} catch (error) {
		console.error('Error uploading thumbnail:', error);
		throw error;
	}
};
// Function to upload a thumbnail
export const uploadQuizImage = async (file, name, type) => {
	const uniqueName = generateUniqueName(name);

	// Define the S3 upload parameters
	const command = new PutObjectCommand({
		Bucket: AWS_S3_BUCKET_NAME,
		Key: `quizzes/images/${uniqueName}`, // Unique name for the thumbnail
		Body: file, // File content
		ContentType: type, // MIME type of the file
	});

	try {
		// Send the upload command to S3
		const response = await client.send(command);
		console.log('Thumbnail uploaded successfully:', response);

		// Return the S3 URL of the uploaded file
		return `https://${AWS_S3_BUCKET_NAME}.s3.${AWS_REGION}.amazonaws.com/quizzes/images/${uniqueName}`;
	} catch (error) {
		console.error('Error uploading thumbnail:', error);
		throw error;
	}
};
