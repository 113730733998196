import React, { useState, useRef } from 'react';
import { useCreateMutation } from '../../query';
import { toast } from 'react-toastify';
import { MdDelete } from 'react-icons/md';
import { uploadQuizImage } from '../../aws/s3';

const QuizForm = ({
	selectedChapter,
	setSelectedChapter,
	setSelectedSubject,
}) => {
	const [question, setQuestion] = useState('');
	const [options, setOptions] = useState(['', '']);
	const [correctAnswer, setCorrectAnswer] = useState('');
	const [quizType, setQuizType] = useState('simple');
	const [media, setMedia] = useState({ image: '', audio: '', video: '' });
	const [quizImage, setQuizImage] = useState({
		file: null,
		src: null,
	});

	const fileInputRef = useRef(null);

	const createMutation = useCreateMutation();

	// Handle option change
	const handleOptionChange = (index, value) => {
		const updatedOptions = options.map((option, i) =>
			i === index ? value : option
		);
		setOptions(updatedOptions);
	};

	// Add option to the options list
	const handleAddOption = () => {
		if (options.length < 6) {
			setOptions([...options, '']);
		}
	};

	const handleRemoveOption = (index) => {
		const updatedOptions = options.filter((_, i) => i !== index);
		setOptions(updatedOptions);
	};

	const handleQuizTypeChange = (e) => {
		setQuizType(e.target.value);
		// Reset media fields when quiz type changes
		setMedia({ image: '', audio: '', video: '' });
	};

	// Handle media input
	const handleMediaChange = (e) => {
		setMedia({ ...media, [e.target.name]: e.target.value });
	};

	// Save the quiz data
	const handleSaveQuiz = async () => {
		// const newQuestionID = `Q${Math.floor(Math.random() * 10000)}`;
		// setQuestionID(newQuestionID);

		if (!selectedChapter) {
			toast.error('Please select a chapter.');
			return;
		}

		if (!question) {
			toast.error('Please enter a question.');
			return;
		}

		if (options.length < 2) {
			toast.error('Please add at least two options.');
			return;
		}

		// Check for empty options
		for (const option of options) {
			if (!option.trim()) {
				// Checks if the option is empty or only whitespace
				toast.error('Options should not be empty.');
				return;
			}
		}

		if (!correctAnswer) {
			toast.error('Please select a correct answer.');
			return;
		}

		if (quizType === 'imageBased' && !quizImage.file) {
			toast.error('Please upload an image for this quiz.');
			return;
		}

		let responseImage;
		if (quizType === 'imageBased' && quizImage.file) {
			responseImage = await uploadImage(
				quizImage.file,
				question[0],
				quizImage.file.type
			);

			if (!responseImage) {
				toast.error('Error uploading file.');
				return;
			}
		}

		const quizData = {
			chapter: selectedChapter,
			question,
			options,
			correctAnswer,
			quizType,
			media: {
				image: quizType === 'imageBased' ? responseImage : media.image,
			},
		};

		createMutation.mutate(
			{
				url: 'quiz',
				data: quizData,
				queryKey: 'Quiz',
			},
			{
				onSuccess: () => {
					// toast.success('Quiz saved successfully!');
					resetForm();
				},
				onError: () => {
					toast.error('Failed to save quiz.');
				},
			}
		);
	};

	// Reset form state
	const resetForm = () => {
		setQuestion('');
		setOptions(['', '']);
		setCorrectAnswer('');
		setQuizType('simple');
		setMedia({ image: '', audio: '', video: '' });
	};

	const uploadImage = async (file, name, type) => {
		const response = await uploadQuizImage(file, name, type);
		return response;
	};

	// Thumbnail preview
	const handleImageChange = (file) => {
		if (file) {
			// Validate the file type (accept only JPEG or JPG)
			const validFileTypes = ['image/jpeg', 'image/jpg'];
			if (!validFileTypes.includes(file.type)) {
				alert('Only JPEG/JPG files are allowed');
				return;
			}

			const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
			if (file.size > maxSizeInBytes) {
				alert('Please upload an image smaller than 2 MB.');
				setQuizImage({ file: null, src: null });
				fileInputRef.current.value = null;
				return;
			}

			const img = new Image();
			const reader = new FileReader();

			reader.onloadend = () => {
				img.src = reader.result;
			};

			img.onload = () => {
				if (img.width > img.height) {
					// Landscape image
					setQuizImage({ file, src: img.src });
				} else {
					// Portrait image
					alert('Please upload a landscape image.');
					setQuizImage({ file: null, src: null });
					fileInputRef.current.value = null;
				}
			};

			reader.readAsDataURL(file);
		}
	};

	const handleRemoveImage = (e) => {
		setQuizImage({ file: null, src: null });
		fileInputRef.current.value = null;
	};

	const handleFileDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const files = e.dataTransfer.files;
		if (files.length > 0) {
			handleImageChange(files[0]);
		}
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	return (
		<div>
			<div className="mb-6">
				<label className="block text-gray-700 mb-2">Question</label>
				<input
					type="text"
					className="p-2 border rounded w-full"
					placeholder="Enter your question"
					value={question}
					onChange={(e) => setQuestion(e.target.value)}
				/>
			</div>

			<div className="mb-6">
				<div className="flex items-center mb-2">
					<label className="block text-gray-700 w-full">Options</label>
					<span className="block text-gray-700 ml-2 w-1/5 text-center">
						Correct Option
					</span>
				</div>
				{options.map((option, index) => (
					<div key={index} className="mb-2 flex items-center">
						<input
							type="text"
							className="p-2 border rounded w-full mr-2"
							placeholder={`Option ${index + 1}`}
							value={option}
							onChange={(e) => handleOptionChange(index, e.target.value)}
						/>
						<input
							type="radio"
							name="correctAnswer"
							value={index}
							checked={correctAnswer === index.toString()}
							onChange={() => setCorrectAnswer(index.toString())}
							className="border-2 rounded w-1/5 p-2 size-6"
						/>
						<MdDelete
							onClick={() => handleRemoveOption(index)}
							className="text-red-500 text-xl cursor-pointer"
						/>
					</div>
				))}
				{options.length < 6 && (
					<button
						className="bg-blue-500 text-white p-2 rounded"
						onClick={handleAddOption}
					>
						Add More Options
					</button>
				)}
			</div>

			<div className="mb-6">
				<label className="block text-gray-700 mb-2">Quiz Type</label>
				<select
					value={quizType}
					onChange={handleQuizTypeChange}
					className="p-2 border rounded w-full"
				>
					<option value="simple">Simple</option>
					<option value="imageBased">Image Based</option>
					<option value="audioBased">Audio Based</option>
					<option value="videoBased">Video Based</option>
				</select>
			</div>

			{/* Conditional media input fields */}
			{quizType === 'imageBased' && (
				<div className="mb-6">
					<label className="block text-gray-700">
						Upload Thumbnail (Landscape) (JPEG/JPG, 2 MB max):
					</label>
					<div
						className="border-2 border-dashed border-gray-300 rounded-lg p-4 flex flex-col items-center justify-center cursor-pointer hover:border-blue-500"
						onClick={() => fileInputRef.current.click()}
						onDrop={handleFileDrop}
						onDragOver={handleDragOver}
					>
						<input
							type="file"
							accept="image/*"
							className="hidden"
							onChange={(e) => handleImageChange(e.target.files[0])}
							ref={fileInputRef}
						/>
						<p className="text-gray-500 mb-2">
							Drag and drop your image here or click to select
						</p>
						{quizImage.src ? (
							<img
								src={quizImage.src}
								alt="Thumbnail preview"
								className="w-full h-auto rounded-lg mb-2"
							/>
						) : (
							<p className="text-gray-400">No file selected</p>
						)}
					</div>
					{quizImage.src && (
						<button
							onClick={handleRemoveImage}
							className="mt-1 bg-red-500 text-white p-2 rounded hover:bg-red-600 transition"
						>
							Remove
						</button>
					)}
				</div>
			)}

			{quizType === 'audioBased' && (
				<div className="mb-6">
					<label className="block text-gray-700 mb-2">Audio URL</label>
					<input
						type="text"
						className="p-2 border rounded w-full"
						placeholder="Enter audio URL"
						name="audio"
						value={media.audio}
						onChange={handleMediaChange}
					/>
				</div>
			)}

			{quizType === 'videoBased' && (
				<div className="mb-6">
					<label className="block text-gray-700 mb-2">Video URL</label>
					<input
						type="text"
						className="p-2 border rounded w-full"
						placeholder="Enter video URL"
						name="video"
						value={media.video}
						onChange={handleMediaChange}
					/>
				</div>
			)}

			<button
				className="bg-green-500 text-white p-2 rounded"
				onClick={handleSaveQuiz}
			>
				Save Quiz
			</button>
		</div>
	);
};

export default QuizForm;
